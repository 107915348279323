
.css-14el2xx-placeholder{
  @apply placeholder-gray-900 text-gray-900 !important;
}

.css-qc6sy-singleValue, .css-6j8wv5-Input, .css-1pndypt-Input input{
  @apply pl-5;
}

.css-qc6sy-singleValue{
  @apply mt-px;
}

