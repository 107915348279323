
.btn {
  @apply text-base px-4 outline-none focus:outline-none ease-linear transition-all duration-150 py-3;
  border-radius: 3px;
}

.btnPrimary {
  @apply bg-primary-600 text-white hover:bg-primary-700 focus:bg-primary-600;
}

.btnSecondary {
  @apply bg-gray-100 text-gray-500;
}

.btnOutline {
  @apply bg-white;
}

.disabled {
  @apply bg-gray-50 pointer-events-none hover:bg-gray-100 text-gray-400;
}

