.pagingButton{
  @apply relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-normal rounded-md text-gray-700 bg-white hover:bg-primary-50 hover:text-gray-600;
}

.disabledButton{
  @apply bg-gray-100 pointer-events-none hover:bg-gray-100 text-gray-400;
}

.paginationItem{
   @apply w-8 h-8 mr-1 rounded-md hover:bg-gray-100  text-sm hover:text-gray-500 text-gray-500;
}

.paginationItemActive{
  @apply bg-primary-50  text-primary-600 hover:bg-primary-50 hover:text-primary-600;
}