/* ./src/tailwind.css */
@tailwind base;
@tailwind components;
@font-face {
    font-family: 'Larsseit';
    font-weight: 100;
    font-style: italic;
    src:  url('./fonts/Larsseit/Larsseit-ThinItalic.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Larsseit';
    font-weight: 100;
    src:  url('./fonts/Larsseit/Larsseit-Thin.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Larsseit';
    font-weight: 300;
    src:  url('./fonts/Larsseit/Larsseit-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Larsseit';
    font-weight: 400;
    src:  url('./fonts/Larsseit/Larsseit.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Larsseit';
    font-weight: 500;
    src:  url('./fonts/Larsseit/Larsseit-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Larsseit';
    font-weight: 700;
    src: url('./fonts/Larsseit/Larsseit-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Larsseit';
    font-weight: 800;
    src:  url('./fonts/Larsseit/Larsseit-ExtraBold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Larsseit';
    font-weight: 800;
    font-style: italic;
    src:  url('./fonts/Larsseit/Larsseit-ExtraBoldItalic.ttf') format('truetype');
  }
@tailwind utilities;
