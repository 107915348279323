.navLink
{
  @apply font-normal text-base  h-full;
}

.topBar{
  height: 3.875rem;
  @apply pt-3.5 pb-5;
}


